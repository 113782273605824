import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./menu.module.css";

function Menu(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <li className={variant}></li>
    );
};
function Submenu(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <div></div>
    );
}
Menu.Submenu = Submenu;

export default Menu;