
import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import { ThemeContext } from "../../../extra-components/contexts";
import styles from './icon.module.css';

function Icon(props) {
  const variant = ClassMapper.attach(styles, props.variant);
  return (
    <i onClick={props.onClick} onChange={props.onChange} className={variant}></i>
  );
};

function Theme(props) {
  const light = ClassMapper.attach(styles, { classes: "theme-button sml fa-moon fa-solid" });
  const dark = ClassMapper.attach(styles, { classes: "theme-button sml fa-solid fa-sun" });
  const states = { "light": light, "dark": dark };
  var [variant, setVariant] = useState(states['light']);
  const { theme, setTheme } = useContext(ThemeContext);

  const calcTheme = useMemo(() => {
    return (theme === "light" ? "dark" : "light");
  }, [theme]);
  const handleClick = (e) => {
    e.preventDefault();
    setTheme(calcTheme);
    setVariant(states[theme]);
  }
  useEffect(() => {
    setVariant(states[theme]);
  }, [states, theme]);
  return (
    <i onClick={handleClick} className={variant}></i>
  )
}


function Shape(props) {
  const variant = ClassMapper.attach(styles, props.variant);
  return (
    <div className={variant}>{props.children}</div>
  );
}

Icon.Theme = Theme;
Icon.Shape = Shape;
export default Icon;
