import React, { useState, useEffect, useContext } from "react";
import { Grid, Container, Flex, Footer, HeaderBar, Image, Span, Ryan, Kolten, ParallaxBGSVG, MadeInIdaho, Meeting } from "../../common";



function Company(props) {

    return (
        <div>
            <HeaderBar></HeaderBar>
            <Flex.Container variant={{ classes: "parallax-container" }}>
                <Flex.Container variant={{ classes: "parallax-bg" }}>
                    <Image variant={{ classes: "parallax-overlay" }} src={ParallaxBGSVG}></Image>
                </Flex.Container>
                <section id="about">
                    <Flex.Container variant={{ classes: "flex-container-column" }}>
                        <Grid variant={{ classes: "two-col sub-width page-row " }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column" }}>
                                    <Span variant={{ classes: "main-title-text left" }} text="About Us"></Span>
                                    <Span variant={{ classes: "main-sub-text" }} text="Our mission is to provide fully managed, automated, and enterprise level analytics solutions to businesses regardless of industry or size."></Span>
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                </Container>
                            </Grid.Item>
                        </Grid>
                    </Flex.Container>
                </section>

                <Grid variant={{ classes: "one sub-width page-row" }}>
                    <Span variant={{ classes: "sub-title-text" }} text="Our Founders"></Span></Grid>
                <section id="leadership">
                    <Flex.Container variant={{ classes: "flex-container-column" }}>
                        <Grid variant={{ classes: "two-col sub-width page-row" }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column" }}>
                                    <Flex.Container variant={{ classes: "container-column center-all company-contact-card" }}>
                                        <Image variant={{ classes: "profile-image" }} src={Ryan} />
                                        <Span variant={{ classes: "profile-name" }} text="Ryan Roline"></Span>
                                        <Span variant={{ classes: "company-body-text" }} text="Ryan graduated with a Master's in Data Analytics and has over 10 years of experience in the industry. He is well versed in Python development, data automation, data engineering, advanced statistical analysis, machine learning, and front end development. When he's not working, Ryan enjoys downhill mountain biking, snowboarding, and spending time with his wife Mindy, and their son Shay."></Span>
                                    </Flex.Container>
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Flex.Container variant={{ classes: "container-column center-all company-contact-card" }}>
                                    <Image variant={{ classes: "profile-image" }} src={Kolten} />
                                    <Span variant={{ classes: "profile-name" }} text="Kolten Fluckiger"></Span>
                                    <Span variant={{ classes: "company-body-text" }} text="Kolten is a DevOps Engineer who has been programming for over 9 years, and has been a developer in the field for over three. From DevOps to software engineering he has studied and is well versed in multiple languages. Kolten believes that, whatever the goal, whatever the vision; technology can get you there and make it work for you. In his free time he enjoys spending time with his friends and family, PC Gaming, and coding the next new project. He is always motivated to further his knowledge and expand his abilities."></Span>
                                </Flex.Container>
                            </Grid.Item>
                        </Grid>
                    </Flex.Container>
                </section>



                <section id="careers">
                <Flex.Container variant={{ classes: "flex-container-column" }}>
                    <Grid variant={{ classes: "two-col sub-width page-row" }}>
                        <Grid.Item variant={{ classes: "full-sz" }}>
                            <Container variant={{ classes: "flex-container-column" }}>
                                <Span variant={{ classes: "sub-title-text" }} text="Join the Team"></Span>
                                <Span variant={{ classes: "sub-body-text" }} text="As we continue to scale, recruiting top talent is always at the forefront of our minds. If you are looking to join a close knit team of developers and start the next step in your career journey, CONTACT US. We look forward to hearing from you!"></Span>
                            </Container>
                        </Grid.Item>
                        <Grid.Item variant={{ classes: "full-sz" }}>
                            <Container variant={{ classes: "flex-container-column flex-center" }}>
                                <Image variant={{ classes: "lg-icon m-image-margin" }} src={Meeting} />
                            </Container>
                        </Grid.Item>
                    </Grid>
                </Flex.Container>
                </section>

                <section id="involve">
                <Flex.Container variant={{ classes: "flex-container-column" }}>
                    <Grid variant={{ classes: "two-col sub-width page-row" }}>
                        <Grid.Item variant={{ classes: "full-sz" }}>
                            <Container variant={{ classes: "flex-container-column" }}>
                                <Span variant={{ classes: "sub-title-text" }} text="Local Involvement"></Span>
                                <Span variant={{ classes: "sub-body-text" }} text="We take pride in our roots and make it a point to give back to the community around us. If you have a local cause or non-profit that needs volunteers, we would love to help! Reach out to info@koresights.com to get in touch with us."></Span>
                            </Container>
                        </Grid.Item>
                        <Grid.Item variant={{ classes: "full-sz" }}>
                            <Container variant={{ classes: "flex-container-column flex-center" }}>
                                <Image variant={{ classes: "lg-icon m-image-margin" }} src={MadeInIdaho} />
                            </Container>
                        </Grid.Item>
                    </Grid>
                </Flex.Container>
                </section>



            </Flex.Container>
            <Footer></Footer>

        </div >

    );
};

export default Company;