import React, { useState, useEffect, useContext } from "react";
import { Grid, Container, Flex, Footer, Navigation, HeaderBar, Image, Icon, Link, Span, Button, KoresightsAbstractIcon, KoresightsLogo, ProcessMap, ProcessMapVert, SampleUIDark, SampleUI, SampleUIUser, ParallaxBGSVG, FactorySVG, DatatoolsSVG, RocketSVG, DeskTeamSVG, DevTeamSVG, AiSVG } from "../../common";


function Home(props) {

    return (
        <div>
            <HeaderBar></HeaderBar>

            <Flex.Container variant={{ classes: "parallax-container" }}>


                <Flex.Container variant={{ classes: "parallax-bg" }}>
                    <Image variant={{ classes: "parallax-overlay" }} src={ParallaxBGSVG}></Image>
                </Flex.Container>

                <Flex.Container variant={{ classes: "flex-container-column" }}>
                    <Grid variant={{ classes: "two-col sub-width page-row " }}>
                        <Grid.Item variant={{ classes: "full-sz" }}>
                            <Container variant={{ classes: "flex-container-column flex-center" }}>
                                <Span variant={{ classes: "main-title-text" }} text="Go beyond business intelligence."></Span>
                                <Span variant={{ classes: "main-sub-text" }} text="A fully managed cloud-based analytics solution designed to provide your teams with a singular source of truth that is automated, predictive, and scalable."></Span>
                                <Flex.Container variant={{ classes: "btn-row full-width" }}>
                                    <Link href="/pricing">
                                        <Button variant={{ classes: "btn-base btn-light main-link margin-right" }} text="See Plans" /></Link>
                                    
                                        <Button variant={{ classes: "btn-base btn-light-hollow main-link margin-left" }} to={() => window.location = 'mailto:info@koresights.com'} text="Contact Sales" />
                                </Flex.Container>
                            </Container>
                        </Grid.Item>
                        <Grid.Item variant={{ classes: "full-sz" }}>
                            <Container variant={{ classes: "flex-container-column flex-center" }}>
                                <Image variant={{ classes: "big-graphic-l m-image-margin m-image-size" }} src={FactorySVG} />
                            </Container>
                        </Grid.Item>
                    </Grid>
                    <Flex.Container variant={{ classes: "container center-all" }}>
                        <Span variant={{ classes: "sub-title-text header-spacing m-center" }} text="Designed to be the last solution you ever need."></Span></Flex.Container>
                        <Grid variant={{ classes: "m-compare-card rows-auto padded-mdm" }}>
                            <Flex.Container>
                                <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                    <Icon variant={{ classes: "fa-solid fa-users card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Unlimited user licenses on every plan "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                    <Icon variant={{ classes: "fa-solid fa-arrows-rotate card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Supports weekly, daily, and live refreshes "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row  padded-sm evenly text-center " }}>
                                    <Icon variant={{ classes: "fa-solid fa-brain-circuit card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Machine learning and predictive analytics "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                    <Icon variant={{ classes: "fa-solid fa-database card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Dedicated and scalable backend "></Span>
                                </Flex.Container>
                            </Flex.Container>
                            <Flex.Container variant={{ classes: "center-all" }}><Link href="/pricing"><Button variant={{ classes: "btn-base btn-light-hollow main-link card-link" }} text="Learn More" /></Link></Flex.Container>
                        </Grid>
                    <Grid variant={{ classes: "two-mdm-gap gap-xlrg sub-width" }}>

                        <Grid variant={{ classes: "compare-card rows-auto pure-dark grid-card rounded padded-mdm" }}>
                            <Flex.Container><Span variant={{ classes: "sub-title-text card-header-spacing" }} text="Us"></Span></Flex.Container>
                            <Flex.Container>
                                <Flex.Container variant={{ classes: "container-row card-borders padded-sm evenly text-center end-margins" }}>
                                    <Icon variant={{ classes: "fa-solid fa-users card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Unlimited user licenses on every plan "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row card-borders padded-sm evenly text-center end-margins" }}>
                                    <Icon variant={{ classes: "fa-solid fa-arrows-rotate card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Supports weekly, daily, and live refreshes "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row card-borders padded-sm evenly text-center end-margins" }}>
                                    <Icon variant={{ classes: "fa-solid fa-brain-circuit card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Machine learning and predictive analytics "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row card-borders padded-sm evenly text-center end-margins" }}>
                                    <Icon variant={{ classes: "fa-solid fa-database card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Dedicated and scalable backend "></Span>
                                </Flex.Container>
                            </Flex.Container>
                            <Flex.Container variant={{ classes: "center-all" }}><Link href="/pricing"><Button variant={{ classes: "btn-base btn-light-hollow main-link card-link" }} text="Learn More" /></Link></Flex.Container>
                        </Grid>
                        <Grid variant={{ classes: "rows-auto pure-dark grid-card rounded padded-mdm compare-card" }}>
                            <Flex.Container><Span variant={{ classes: "sub-title-text card-header-spacing" }} text="The Competition"></Span></Flex.Container>
                            <Flex.Container>
                                <Flex.Container variant={{ classes: "container-row card-borders padded-sm evenly text-center end-margins" }}>
                                    <Icon variant={{ classes: "fa-solid fa-hexagon-xmark card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Expensive licensing plans "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row card-borders padded-sm evenly text-center end-margins" }}>
                                    <Icon variant={{ classes: "fa-solid fa-hexagon-xmark card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Delayed or inconsistent refresh periods "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row card-borders padded-sm evenly text-center end-margins" }}>
                                    <Icon variant={{ classes: "fa-solid fa-hexagon-xmark card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Apply one size fits all AI models "></Span>
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row card-borders padded-sm evenly text-center end-margins" }}>
                                    <Icon variant={{ classes: "fa-solid fa-hexagon-xmark card-icons icon-size-med" }} />
                                    <Span variant={{ classes: "main-sub-text mg-right-auto" }} text="Shared resources and more down time "></Span>
                                </Flex.Container>
                            </Flex.Container>
                            <Flex.Container variant={{ classes: "center-all" }}><Link href="/pricing"><Button variant={{ classes: "btn-base btn-light-hollow main-link card-link" }} text="Explore the Differences" /></Link></Flex.Container>
                        </Grid>
                    </Grid>
                </Flex.Container>


                <Flex.Container variant={{ classes: "flex-container-column" }}>
                    <Grid variant={{ classes: "two-col sub-width page-row m-grid-border" }}>
                        <Grid.Item variant={{ classes: "full-sz" }}>
                            <Container variant={{ classes: "flex-container-column flex-center" }}>
                                <Image variant={{ classes: "big-graphic-r m-image-b m-image-size " }} src={RocketSVG} />
                            </Container>
                        </Grid.Item>
                        <Grid.Item variant={{ classes: "full-sz" }}>
                            <Container variant={{ classes: "flex-container-column flex-center" }}>
                                <Span variant={{ classes: "sub-title-text" }} text="Putting actionable insights at your
                                fingertips is our mission."></Span>
                                <Span variant={{ classes: "sub-body-text" }} text="Our system is designed to turn your organizations data into
                                quick and easy insights that you can use to make everyday
                                business decisions. This takes all of the heavy lifting off of
                                you and your team so that you can spend more time doing
                                the things that matter."></Span>
                            </Container>
                        </Grid.Item>
                    </Grid>
                    <section id="visualization">
                        <Grid variant={{ classes: "three-auto grid-gap-mdm gap-xlrg sub-width" }}>
                            <Grid variant={{ classes: "two-rows-fixed" }}>
                                <Flex.Container variant={{ classes: "color-step-bg-1 small-card-top  empty" }}><Image variant={{ classes: "card-top-img" }} src={SampleUI}></Image></Flex.Container>
                                <Flex.Container variant={{ classes: "flex-container-column bottom-container" }}>
                                    <Span variant={{ classes: "card-header-font" }} text="UI that is simple and easy to use"></Span>
                                    <Span variant={{ classes: "card-body-font" }} text="Whether you are a technology guru or just getting started - our UI is designed to make it easy for users at all skill levels to get the data they need."></Span>
                                </Flex.Container>
                            </Grid>
                            <Grid variant={{ classes: "two-rows-fixed" }}>
                                <Flex.Container variant={{ classes: "small-card-top color-step-bg-2 empty" }}><Image variant={{ classes: "card-top-img" }} src={SampleUIDark}></Image></Flex.Container>
                                <Flex.Container variant={{ classes: "flex-container-column bottom-container" }}>
                                    <Span variant={{ classes: "card-header-font" }} text="Dark mode for night owls"></Span>
                                    <Span variant={{ classes: "card-body-font" }} text="We understand that everyone has their own schedule. With dark mode, you can continue working on your time without causing additional strain to your eyes."></Span>
                                </Flex.Container>
                            </Grid>
                            <Grid variant={{ classes: "two-rows-fixed" }}>
                                <Flex.Container variant={{ classes: "small-card-top color-step-bg-3 empty" }}><Image variant={{ classes: "card-top-img" }} src={SampleUIUser}></Image></Flex.Container>
                                <Flex.Container variant={{ classes: "flex-container-column bottom-container" }}>
                                    <Span variant={{ classes: "card-header-font" }} text="Tool tips and layered analysis"></Span>
                                    <Span variant={{ classes: "card-body-font" }} text="It always important to get the most out of your data. With custom drill through layers, you can gain unique insight into your data that wasn't previously possible."></Span>
                                </Flex.Container>
                            </Grid>


                        </Grid>
                    </section>




                </Flex.Container>

                <section id="cleanse">
                    <Flex.Container variant={{ classes: "flex-container-column" }}>
                        <Grid variant={{ classes: "two-col sub-width page-row m-grid-border" }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Span variant={{ classes: "sub-title-text" }} text="Unified data sources provide consistent
                                data to each pillar of your business."></Span>
                                    <Span variant={{ classes: "sub-body-text" }} text="We take pride in investing time up front to ensure that
                                you have a clean and consistent data source for each of your teams to use. This reduces internal friction and improves overall communication and efficiency."></Span>
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Image variant={{ classes: "big-graphic-l m-image-t m-image-size" }} src={DeskTeamSVG} />
                                </Container>
                            </Grid.Item>
                        </Grid>
                    </Flex.Container>

                    <Flex.Container variant={{ classes: "flex-container-column" }}>
                        <Grid variant={{ classes: "two-col sub-width page-row m-grid-border" }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Image variant={{ classes: "big-graphic-r m-image-b m-image-size" }} src={DatatoolsSVG} />
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Span variant={{ classes: "sub-title-text" }} text="Standardize your data practices and
                                increase operational efficiencies."></Span>
                                    <Span variant={{ classes: "sub-body-text" }} text="When you and your team spend less time pulling data you
                                have more time to spend analyzing it. Koresights works
                                behind the scenes to bring you a fully automated data
                                solution that you can rely on."></Span>
                                </Container>
                            </Grid.Item>

                        </Grid>
                    </Flex.Container>
                </section>


                <Flex.Container variant={{ classes: "container-column center-all" }}>
                    <Span variant={{ classes: "sub-title-text header-spacing" }} text="Simple. Elegant. Effective."></Span>
                    <Image variant={{ classes: "process-map" }} src={ProcessMap} />
                    <Image variant={{ classes: "process-map-vert" }} src={ProcessMapVert} />
                </Flex.Container>




                <section id="ml">
                    <Flex.Container variant={{ classes: "flex-container-column" }}>
                        <Grid variant={{ classes: "two-col sub-width page-row m-grid-border" }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Span variant={{ classes: "sub-title-text" }} text="Machine learning and predictive
                                analytics at your fingertips."></Span>
                                    <Span variant={{ classes: "sub-body-text" }} text="Our algorithms are designed to provide you with views into
                                your data that you never thought possible. Combine
                                that with power of predictive analytics and you have insights
                                that can drive your business to the next level."></Span>
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Image variant={{ classes: "big-graphic-l m-image-t m-image-size" }} src={AiSVG} />
                                </Container>
                            </Grid.Item>
                        </Grid>
                    </Flex.Container>
                </section>
                <section id="scale">
                    <Flex.Container variant={{ classes: "flex-container-column" }}>
                        <Grid variant={{ classes: "two-col sub-width page-row m-grid-border" }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Image variant={{ classes: "big-graphic-r m-image-b m-image-size" }} src={DevTeamSVG} />
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Span variant={{ classes: "sub-title-text" }} text="Fully automated data warehouses
                                designed to scale with your business."></Span>
                                    <Span variant={{ classes: "sub-body-text" }} text="We setup a data pipeline specifically tailored to fit your
                                business. Your data is automatically retrieved and inserted
                                into your Koresights data warehouse where it is cleaned
                                before being turned in to actionable insights for your team."></Span>
                                </Container>
                            </Grid.Item>
                        </Grid>
                    </Flex.Container>
                </section>
            </Flex.Container>
            <Footer></Footer>

        </div >

    );
};

export default Home;