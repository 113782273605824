import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./header-bar.module.css";
import Navigation from "../navigation";
import { Link, Button, Flex, Image, Icon, KoresightsAbstractIcon, KoresightsLogo } from "../../common";
import { mainnav } from "../../../static/navigationlinks";
import { useWindowScrollPositions } from "../../../static/scrollpos";


function HeaderBar(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    const scrollPos = useWindowScrollPositions();

    const [navbarOpen, setNavbarOpen] = useState(false);

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen);
        console.log(navbarOpen);
    };


    return (
        <div id="headerbar" data-scroll={scrollPos} >
            <Flex.Container variant={{ classes: `header-bar ${navbarOpen ? "header-bar-expand" : ""}` }}>
                <Flex.Container variant={{ classes: "logo-group" }}>
                    <a class="header-link" href="/">
                    <Image variant={{ classes: "company-header-icon" }} src={KoresightsAbstractIcon} /></a>
                    <a class="header-link" href="/">
                    <Image.SVG variant={{ classes: "company-header-logo" }} src={KoresightsLogo} /></a>
                    
                    <i onClick={handleToggle}><Icon variant={{ classes: "fa-duotone fa-bars header-icon" }}></Icon></i>
                </Flex.Container>

                <Navigation links={mainnav} variant={{ classes: "full-sz navigation-top-bar" }} />
                <Flex.Container variant={{ classes: "full-sz login-button-container" }}>
                    <Link variant={{ classes: "btn-base btn-light three-quarter login-link " }} href="/login" text="Login" />
                </Flex.Container>

                <Flex.Container variant={{ classes: `hamburger-menu ${navbarOpen ? "hamburger-menu-shown" : ""}` }}>
                    <Navigation links={mainnav} variant={{ classes: "navigation-top-bar" }} />

                    <Flex.Container variant={{ classes: "login-button-container" }}>
                        <Link variant={{ classes: "btn-base btn-light three-quarter login-link " }} href="/login" text="Login" />
                    </Flex.Container>

                </Flex.Container>

            </Flex.Container>
        </div>
    );
};

export default HeaderBar;


