import React from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from './image.module.css';
import { ReactSVG } from "react-svg";


function Image(props) {
  const variant = ClassMapper.attach(styles, props.variant);
  return (
    <img className={variant} src={props.src} />
  );
};

function SVG(props) {
  const variant = ClassMapper.attach(styles, props.variant);

  return (
    <ReactSVG className={variant} src={props.src}></ReactSVG>
  );
}

Image.SVG = SVG;
export default Image;
