import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./flex.module.css";

// This is not meant to be used. Only for a decoration class.
function Flex() {
    return (
        <div></div>
    );
};
function Container(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <div className={variant}>{props.children}</div>
    );
};
Flex.Container = Container;
export default Flex;