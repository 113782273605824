import React, { Component, Fragment } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import PropTypes from 'prop-types';
import styles from "./loading-component.module.css";

class LoadingComponent extends React.PureComponent {

  constructor(props) {
    super(props);
    this.variant = ClassMapper.attach(styles, { classes: "lds-ellipsis show" });
  }

  render() {
    const { loading, variant } = this.props;
    if (loading) {
      return (<div style={{ paddingTop: "2rem", textAlign: "center" }}><div className={variant}><div></div><div></div><div></div><div></div></div>{this.props.children}</div>)
    }
    else {
      return (<Fragment>{this.props.children}</Fragment>)
    }
  }
}

export default LoadingComponent;
