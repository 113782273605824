const scrollWithOffset = (el) => {
    const app = document.getElementById("app");

    const yCoordinate = el.getBoundingClientRect().top + app.scrollTop;
    const yOffset = -130; 
    app.scrollTo(0, yCoordinate + yOffset); 
}


export default scrollWithOffset;
