import React, { Component } from 'react';
import ClassMapper from "sass-css-modules-class-mapper";

import styles from "./span.module.css";

class Span extends React.PureComponent {

  constructor(props) {
    super(props);
    this.variant = ClassMapper.attach(styles, props.variant);
    this.text = props.text;
    this.onClick = props.onClick;
  }
  render() {
    return (
      <span className={this.variant} onClick={this.onClick}>{this.text}</span>
    )
  }
}

export default Span;
