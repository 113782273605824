import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeContext } from "../../extra-components/contexts";

import Login from "./login";
import Home from "./home";
import Pricing from "./pricing";
import Company from "./company";


function App(props) {
    const [theme, setTheme] = useState('light');
    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <div id="theme" data-theme={theme} style={{ height: "100%", width: "100%" }}>
                <BrowserRouter basename="/">
                    <Routes>
                    <Route path="/" exact element={<Home/>}/>
                    <Route path="/login"  exact element={<Login/>}/>
                    <Route path="/company"  exact element={<Company/>}/>
                    <Route path="/pricing"  exact element={<Pricing/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </ThemeContext.Provider >
    );
};

export default App;