import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./card.module.css";
import { Grid, Icon, Span } from "../../common";
import Flex from "../flex";
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

function Card(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <div className={variant}>{props.children}</div>
    );
};

function KPI(props) {
    const variant = ClassMapper.attach(styles, props.variant);

    const options = {
        responsive: true,
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            }
        },
        elements: {
            line: {
                tension: .5,
            },
            point: {
                radius: 0,
            }
        },
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                borderColor: '#FE5F55',
                backgroundColor: 'rgba(254, 95, 85, .1)',
                fill: 'origin',
            }
        ]
    };


    return (
        <Grid.Container variant={{ classes: "two-column-set pure-color rounded padded-mdm" }}>
            <Grid.Container variant={{ classes: "four-row-fixed" }}>
                <Flex.Container variant={{ classes: "container padded-xxs" }}><Span variant={{ classes: "kpi-label" }} text={props.data_label}></Span></Flex.Container>
                <Flex.Container variant={{ classes: "container padded-xxs" }}><Span variant={{ classes: "kpi-callout lrg center" }} text={props.data_point}></Span></Flex.Container>
                <Flex.Container variant={{ classes: "container padded-xxs" }}><Span variant={{ classes: "trend-label mdm" }} text={props.trend_data}></Span></Flex.Container>
            </Grid.Container>
            <Flex.Container>
                <Line
                    options={options}
                    data={data} />
            </Flex.Container>


        </Grid.Container>

    );
};


Card.KPI = KPI
export default Card;




