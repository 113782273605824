import React, { Component } from 'react';
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./form.module.css";

class Form extends React.PureComponent {

  encodingTypes = {
    urlEncoded: "application/x-www-form-urlencoded",
    multiPart: "multipart/form-data"
  }

  constructor(props) {
    super(props);
    this.id = props.id;
    this.variant = ClassMapper.attach(styles, props.variant)
    this.preventEnterSubmit = props.preventEnterSubmit ? props.preventEnterSubmit : false;
    this.encType = this.encodingTypes[props.encType];
    this.handleSubmit = props.handleSubmit;
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
  }

  handleOnKeyPress(e) {
    if ((e.which === 13) && (this.preventEnterSubmit)) {
      e.preventDefault();
    }
  }

  render() {
    return (<form className={this.variant} id={this.id} onSubmit={this.handleSubmit} encType={this.encType} onKeyPress={this.handleOnKeyPress}>{this.props.children}</form>)
  }
}

export default Form;
