import * as React from "react";
import { createRoot } from 'react-dom/client';
import App from "./components/app";

const app_container = document.getElementById('app');
const root = createRoot(app_container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
