import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./dropdown.module.css";


function DropDown(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (

        <dd>
            <label for="touch"><span>Department</span></label>
            <input type="checkbox" id="touch" />
            <ul className={variant}>
                {props.options.map((option) => <li><a href={option.link}>{option.text}</a></li>)}
            </ul>
        </dd>

    );
};

export default DropDown;
