import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./grid.module.css";

function Grid(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <div className={variant}>{props.children}</div>
    );
};
function Item(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <div className={variant}>{props.children}</div>
    );
};
function Container(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (<div className={variant}>{props.children}</div>);
}
Grid.Item = Item;
Grid.Container = Container;
export default Grid;