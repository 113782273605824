import React, { useState, useEffect, useContext } from "react";
import { Grid, Container, CarouselMod, Flex, Footer, HeaderBar, Image, Icon, Span, DevCostTable, Timeline, TimelineVert, ParallaxBGSVG, MeetingGroupSVG } from "../../common";



function Pricing(props) {

    return (
        <div>
            <HeaderBar></HeaderBar>

            <Flex.Container variant={{ classes: "parallax-container" }}>


                <Flex.Container variant={{ classes: "parallax-bg" }}>
                    <Image variant={{ classes: "parallax-overlay" }} src={ParallaxBGSVG}></Image>
                </Flex.Container>

                <Flex.Container variant={{ classes: "flex-container-column" }}>
                    <section id="plans">
                        <Grid variant={{ classes: "two-col sub-width page-row " }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Span variant={{ classes: "main-title-text" }} text="A pricing plan designed just for you"></Span>
                                    <Span variant={{ classes: "main-sub-text" }} text="We structure our pricing plans to meet the unique needs of your business and strive to provide you with the flexibilty you need at one all-inclusive price."></Span>
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                            </Grid.Item>
                        </Grid>
                    </section>
                    <Flex.Container variant={{ classes: "container center-all" }}>
                        <Span variant={{ classes: "sub-title-text header-spacing m-center m-margin" }} text="Unlimited licenses. Every plan. Always."></Span></Flex.Container>
                    <section id="compare">

                        <Grid variant={{ classes: "full-grid pricing-grid sub-width" }}>
                            <Grid variant={{ classes: "rows-auto pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Kore"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-chart-simple pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="$1999/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Weekly data refreshes "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Includes 5 reports "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Company branding"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="User management "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Access control lists "></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>

                            <Grid variant={{ classes: "rows-auto pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Adaptive"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-chart-network pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="$2499/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Kore "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Live reporting"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Daily data refreshes"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Power BI integration"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Extended ACLs"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                            <Grid variant={{ classes: "rows-auto pricing-card-purp grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles-lt" }} text="Essential"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-brain-circuit pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border-lt" }}>
                                    <Span variant={{ classes: "pricing-sub-titles-lt" }} text="$2999/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Adaptive"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Predictive analytics"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Custom forecasting models"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Slack & Teams integration"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Email notifications"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                            <Grid variant={{ classes: "rows-auto pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Complete"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-microchip pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="Contact Us"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Essential"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Report writer"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="API access"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="24/7 support"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Dedicated support rep"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                        </Grid>

                        <CarouselMod variant={{ classes: "m-carousel" }}>
                            <Grid variant={{ classes: "rows-auto c-pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Kore"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-chart-simple pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="$1999/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Weekly data refreshes "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Includes 5 reports "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Company branding"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="User management "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Access control lists "></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>

                            <Grid variant={{ classes: "rows-auto c-pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Adaptive"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-chart-network pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="$2499/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Kore "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Live reporting"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Daily data refreshes"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Power BI integration"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Extended ACLs"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                            <Grid variant={{ classes: "rows-auto c-pricing-card-purp grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles-lt" }} text="Essential"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-brain-circuit pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border-lt" }}>
                                    <Span variant={{ classes: "pricing-sub-titles-lt" }} text="$2999/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Adaptive"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Predictive analytics"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Custom forecasting models"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Slack & Teams integration"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Email notifications"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                            <Grid variant={{ classes: "rows-auto c-pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Complete"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-microchip pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="Contact Us"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Essential"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Report writer"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="API access"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="24/7 support"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Dedicated support rep"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                        </CarouselMod>

                        <CarouselMod.Phone variant={{ classes: "phone-carousel" }}>
                            <Grid variant={{ classes: "pricing-rows-auto c-pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Kore"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-chart-simple pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="$1999/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Weekly data refreshes "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Includes 5 reports "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Company branding"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="User management "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Access control lists "></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>

                            <Grid variant={{ classes: "pricing-rows-auto c-pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Adaptive"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-chart-network pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="$2499/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Kore "></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Live reporting"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Daily data refreshes"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Power BI integration"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Extended ACLs"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                            <Grid variant={{ classes: "pricing-rows-auto c-pricing-card-purp grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles-lt" }} text="Essential"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-brain-circuit pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border-lt" }}>
                                    <Span variant={{ classes: "pricing-sub-titles-lt" }} text="$2999/mo"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Adaptive"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Predictive analytics"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Custom forecasting models"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Slack & Teams integration"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Email notifications"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                            <Grid variant={{ classes: "pricing-rows-auto c-pricing-cards grid-card" }}>
                                <Flex.Container variant={{ classes: "container-row between" }}>
                                    <Span variant={{ classes: "pricing-titles" }} text="Complete"></Span>
                                    <Icon variant={{ classes: "fa-solid fa-microchip pricing-header-lt icon-size-med" }} />
                                </Flex.Container>
                                <Flex.Container variant={{ classes: "container-row between bottom-border" }}>
                                    <Span variant={{ classes: "pricing-sub-titles" }} text="Contact Us"></Span>
                                </Flex.Container>
                                <Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Everything in Essential"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Report writer"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="API access"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="24/7 support"></Span>
                                    </Flex.Container>
                                    <Flex.Container variant={{ classes: "container-row padded-sm evenly text-center " }}>
                                        <Icon variant={{ classes: "fa-solid fa-square-check pricing-icons-lt icon-size-med" }} />
                                        <Span variant={{ classes: "main-sub-text-lt mg-right-auto" }} text="Dedicated support rep"></Span>
                                    </Flex.Container>
                                </Flex.Container>
                            </Grid>
                        </CarouselMod.Phone>



                    </section>
                </Flex.Container>

                <section id="details">
                    <Flex.Container variant={{ classes: "container center-all" }}>
                        <Span variant={{ classes: "sub-title-text header-spacing-lg m-center m-margin" }} text="We act as a fully staffed development team at a fraction of the cost."></Span>
                    </Flex.Container>



                    <Flex.Container variant={{ classes: "flex-container-column" }}>
                        <Grid variant={{ classes: "two-col sub-width page-row" }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Image variant={{ classes: "dev-team-table" }} src={DevCostTable} />
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Span variant={{ classes: "sub-title-text" }} text="The same functionality and results, without compromise."></Span>
                                    <Span variant={{ classes: "sub-body-text" }} text="Salaries for IT professionals make it hard for small to medium sized businesses to staff a full development team without breaking the bank. We are here to bridge that gap and give you the tools you need at a consistent and affordable price."></Span>
                                </Container>

                            </Grid.Item>

                        </Grid>

                    </Flex.Container>
                </section>
                <section id="implement">
                    <Flex.Container variant={{ classes: "container center-all " }}>
                        <Span variant={{ classes: "sub-title-text m-center m-margin" }} text="An implementation plan designed to be seamless."></Span>
                    </Flex.Container>
                    <Flex.Container variant={{ classes: "flex-container-column" }}>

                        <Container variant={{ classes: "flex-container-column flex-center" }}>
                            <Image variant={{ classes: "process-timeline" }} src={Timeline} />
                            <Image variant={{ classes: "process-timeline-vert" }} src={TimelineVert} />
                        </Container>

                    </Flex.Container>
                </section>
                <section id="contracts">
                    <Flex.Container variant={{ classes: "container center-all" }}>
                        <Span variant={{ classes: "sub-title-text m-center m-margin" }} text="A year of dedicated service and support."></Span>
                    </Flex.Container>
                    <Flex.Container variant={{ classes: "flex-container-column" }}>
                        <Grid variant={{ classes: "two-col sub-width page-row" }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Span variant={{ classes: "sub-title-text" }} text="Sometimes you need a more flexible solution. We get it."></Span>
                                    <Span variant={{ classes: "sub-body-text" }} text="All Koresights plans come with a twelve month service agreement that waives our up front implementation costs. Month-to-month agreements are available for a fee that varies based on complexity of the integration."></Span>
                                </Container>
                            </Grid.Item>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Container variant={{ classes: "flex-container-column flex-center" }}>
                                    <Image variant={{ classes: "big-graphic-l m-image-size m-image-margin" }} src={MeetingGroupSVG} />
                                </Container>
                            </Grid.Item>
                        </Grid>
                    </Flex.Container>
                </section>
            </Flex.Container>
            <Footer></Footer>

        </div >

    );
};

export default Pricing;