import React, { useState, useEffect, useContext } from "react";
import Label from "../label";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from './input.module.css';

function Input(props) {
  const variant = ClassMapper.attach(styles, props.variant);
  return (
    props.required
      ? (<input ref={props.inputRef} onChange={props.onChange} onBlur={props.onBlur} onKeyPress={props.onKeyPress} onInput={props.onInput} id={props.id} className={variant} name={props.name} autoComplete={props.autoCompleteOn} type={props.type} placeholder={props.placeholder} disabled={props.disabled} defaultValue={props.defaultValue} required="required" />)
      : (<input ref={props.inputRef} onChange={props.onChange} onBlur={props.onBlur} onKeyPress={props.onKeyPress} onInput={props.onInput} id={props.id} className={variant} name={props.name} autoComplete={props.autoCompleteOn} type={props.type} placeholder={props.placeholder} disabled={props.disabled} defaultValue={props.defaultValue} />))
};

function Checkbox(props) {
  const containerVariant = ClassMapper.attach(styles, props.containerVariant);

  return (
    <div className={containerVariant}>
      <Input id={props.id} name={props.name} variant={{ classes: "switch-input" }} type="checkbox" />
      <Label for={props.id} variant={{ classes: "remember" }} text={props.text} />
    </div>
  )
}
Input.Checkbox = Checkbox;
export default Input;
