import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link as RouteLink } from "react-router-dom";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./link.module.css";
import Icon from "../icon";
import Span from "../span";

function Link(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <RouteLink className={variant} to={`${props.href}`} >
            {props.text}
            {props.children}
        </RouteLink >

    );
}
function Nav(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    console.log();
    return (
        <RouteLink className={variant} to={`${props.href}`} >
            <Icon variant={{ classes: `icon-style-light ${props.icon}` }} />
            <Span text={props.name} variant={{ classes: "navigation-name" }}></Span>
        </RouteLink >

    );
}


Link.Nav = Nav;
export default Link;