import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./carousel.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function CarouselMod(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <Carousel className={variant} showArrows="true" showThumbs="false" showStatus="false" infiniteLoop="true" swipeable="true" centerMode="true" centerSlidePercentage={52.5}>
           {props.children}
        </Carousel>
    );
};

function Phone(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <Carousel className={variant} showArrows="true" showThumbs="false" showStatus="false" infiniteLoop="true" swipeable="true" centerMode="true" centerSlidePercentage={90}>
           {props.children}
        </Carousel>
    );
};

CarouselMod.Phone = Phone
export default CarouselMod;