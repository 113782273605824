import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";

import styles from "./container.module.css";
function Container(props) {
  const variant = ClassMapper.attach(styles, props.variant);
  return (
    <div className={variant}>{props.children}</div>
  );
};
export default Container;