import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./navigation.module.css";
import Link from "../link";


function Navigation(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <div className={variant}>{props.links.map((link) => <Link.Nav key={link.name} variant={{ classes: "navigation-container" }} href={link.href} icon={link.icon} name={link.name}></Link.Nav>)} </div>
    );
};

export default Navigation;