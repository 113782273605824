import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './button.module.css';

function Button(props) {
  const variant = ClassMapper.attach(styles, props.variant);
  return (
    <button className={variant} onClick={props.to}>{props.text}</button>
  );
};

export default Button;