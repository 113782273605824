import React from 'react';
import { Link, KoresightsLogo } from "../../common";
import { HashLink } from 'react-router-hash-link';
import scrollWithOffset from '../../../static/scrolloffset';
import styles from "./footer.module.css";
import ClassMapper from "sass-css-modules-class-mapper";
import Grid from "../grid";
import Flex from "../flex";
import Span from "../span";
import Image from "../image";
import Icon from "../icon";


function Footer(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <Flex.Container variant={{ classes: "main-footer-box" }}>
            <Grid variant={{ classes: "two-row-auto" }}>

                <Grid variant={{ classes: "footer-grid vert-spacing" }}>
                    <Flex.Container variant={{ classes: "container-column center-all footer-logo-container" }}>
                    <Image.SVG variant={{ classes: "company-footer-logo" }} src={KoresightsLogo} />
                        <Flex.Container variant={{ classes: "container-row center-all" }}>
                            <a href="https://twitter.com/koresights"><Icon variant={{ classes: "fa-brands fa-twitter footer-icon" }}></Icon></a>
                            <a href="https://discord.gg/Zw7P5TQex4"><Icon variant={{ classes: "fa-brands fa-discord footer-icon" }}></Icon></a>
                            <a href="https://www.facebook.com/profile.php?id=100086463204840"><Icon variant={{ classes: "fa-brands fa-meta footer-icon" }}></Icon></a>
                            <a href="https://www.youtube.com/channel/UCMpmzOHyGI4sOgocK7j9hRA/featured"><Icon variant={{ classes: "fa-brands fa-youtube footer-icon" }}></Icon></a>
                            <a href="https://www.linkedin.com/company/koresights/"><Icon variant={{ classes: "fa-brands fa-linkedin footer-icon" }}></Icon></a>
                            <a href="https://github.com/koresights"><Icon variant={{ classes: "fa-brands fa-github footer-icon" }}></Icon></a>
                        </Flex.Container>
                    </Flex.Container>

                    <Grid variant={{ classes: "three-even" }}>
                        <Flex.Container variant={{ classes: "container-column m-footer-align" }}>
                            <Span variant={{ classes: "card-header-font header-spacing-sm" }} text="Products"></Span>
                            <HashLink to="/#visualization" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Data Visualization"></Span></HashLink>
                            <HashLink to="/#ml" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Machine Learning"></Span></HashLink>
                            <HashLink to="/#scale" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Scalability"></Span></HashLink>
                            <HashLink to="/#automate" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Automation"></Span></HashLink>
                            <HashLink to="/#cleanse" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Cleansing"></Span></HashLink>
                        </Flex.Container>
                        <Flex.Container variant={{ classes: "container-column m-footer-align" }}>
                            <Span variant={{ classes: "card-header-font header-spacing-sm" }} text="Pricing"></Span>
                            <HashLink to="/pricing#plans" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Plans"></Span></HashLink>
                            <HashLink to="/pricing#compare" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Compare"></Span></HashLink>
                            <HashLink to="/pricing#details" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Details"></Span></HashLink>
                            <HashLink to="/pricing#implement" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Implementation"></Span></HashLink>
                            <HashLink to="/pricing#contracts" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Contracts"></Span></HashLink>
                        </Flex.Container>
                        <Flex.Container variant={{ classes: "container-column m-footer-align" }}>
                            <Span variant={{ classes: "card-header-font header-spacing-sm" }} text="Company"></Span>
                            <HashLink to="/company#about" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="About Us"></Span></HashLink>
                            <HashLink to="/company#leadership" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Leadership"></Span></HashLink>
                            <HashLink to="/company#careers" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Careers"></Span></HashLink>
                            <HashLink to="/company#involve" scroll={el => scrollWithOffset(el)}><Span variant={{ classes: "footer-text-lt" }} text="Community Involvement"></Span></HashLink>
                        </Flex.Container>
                    </Grid>

                </Grid>

                <Flex.Container variant={{ classes: "container-row center-all footer-legal" }}><Span variant={{ classes: "footer-text-legal" }} text="Copyright 2022 Koresights LLC. All rights reserved."></Span></Flex.Container>


            </Grid >
        </Flex.Container>
    );
}
export default Footer;
