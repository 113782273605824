import React, { useState, useEffect, useContext } from "react";
import { Grid, Container, SubContainer, Header, HeaderBar, Card, Icon, Form, Flex, Image, KoresightsAbstractIcon, FormGroup, Input, Label, Span, Button, Link, KoresightsLogoBlk } from "../../common";
function Login(props) {

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e);
    }

    return (
        <Container variant={{ classes: "container flex-container flex-center" }}>
            <SubContainer>
                <Grid variant={{ classes: "two grid-card rounded" }}>
                    <Grid.Item variant={{ classes: "full-sz pure-color" }}>
                        <Container variant={{ classes: "flex-container-column" }}>
                                <Flex.Container variant={{ classes: "container-column" }}>
                                <Image variant={{ classes: "login-logo" }} src={KoresightsAbstractIcon} />
                                <Image variant={{ classes: "login-sub-logo" }} src={KoresightsLogoBlk} />
                                </Flex.Container>
                            
                            <Container variant={{ classes: "login-container-header-centered" }}>
                                <Header text="Welcome Back" variant={{ classes: "div-header lrg-letter-spacing sml-letter-padding" }} />
                                <Span variant={{ classes: "description mdm center" }} text="Please enter your details to login." />
                                <Container variant={{ classes: "login-column-container" }}>
                                    <Form id="signin" variant={{ classes: "login-form" }} encType="urlEncoded" handleSubmit={handleSubmit}>
                                        <FormGroup variant={{ classes: "form-group-column" }}>
                                            <FormGroup variant={{ classes: "form-group-column" }}>
                                                <Label for="email" text="Email" variant={{
                                                    classes: "login"
                                                }} />
                                                <Input id="email" required={true} type="text" name="email" variant={{ classes: "login-input" }} placeholder="Enter your email" />
                                            </FormGroup>
                                            <FormGroup variant={{ classes: "form-group-column" }}>
                                                <Label for="password" text="Password" variant={{
                                                    classes: "login"
                                                }} />
                                                <Input id="password" required={true} type="password" name="password" variant={{ classes: "login-input" }} />
                                            </FormGroup>
                                        </FormGroup>
                                        <FormGroup variant={{ classes: "form-group form-group-additional" }}>
                                            <Input.Checkbox containerVariant={{ classes: "input-container" }} text="Remember Me" id="rememberme" name="rememberme"></Input.Checkbox>
                                            <Link to="/reset" text="Forgot Password" variant={{ classes: "forgot-password" }} />
                                        </FormGroup>
                                        <FormGroup variant={{ classes: "form-group form-group-additional" }}>
                                            <Button variant={{ classes: "btn-base btn-accent-color sign-in" }} text="Sign In" />
                                        </FormGroup>
                                    </Form>
                                </Container>
                            </Container>
                        </Container>
                    </Grid.Item>
                    <Grid.Item variant={{ classes: "full-sz opposite-color" }}>
                        <Container variant={{ classes: "flex-container-column flex-center" }}>
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Card variant={{ classes: "content-card" }} />
                            </Grid.Item>
                            <Icon.Shape variant={{ classes: "lrg-circle" }} />
                            <Grid.Item variant={{ classes: "full-sz" }}>
                                <Card variant={{ classes: "content-card mdm-blurred" }} />
                            </Grid.Item>
                        </Container>
                    </Grid.Item>
                </Grid>
            </SubContainer>

        </Container>
    );
};
export default Login;
