import React, { useState, useEffect, useContext } from "react";
import ClassMapper from "sass-css-modules-class-mapper";
import styles from "./header.module.css";

function Header(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <span className={variant}>{props.text}</span>
    );
};


function LogoHeader(props) {
    const variant = ClassMapper.attach(styles, props.variant);
    return (
        <img className={variant} src={props.path} />
    );
};
Header.Logo = LogoHeader
export default Header;