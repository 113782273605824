import { useEffect, useState } from 'react'

export const useWindowScrollPositions = () => {
    const app = document.getElementById("app");
   const [scrollPosition, setPosition] = useState(0)

   useEffect(() => {
    function updatePosition() {
        setPosition(app.scrollTop)
    }

    app.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => app.removeEventListener('scroll', updatePosition)
   }, [])

   return scrollPosition
}


